import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Iframe from 'react-iframe'
import {HalfCircleSpinner} from 'react-epic-spinners';

const ScheduleCall = ({ isOpen, onRequestClose })  => {
    const [iframeSrc, setIframeSrc] = useState('');
    const [mode, setMode] = useState(null);

    useEffect(() => {
        setIframeSrc('https://cal.com/omelatte/30min');

        window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', event => {
            const colorScheme = event.matches ? "dark" : "light";
            setMode(colorScheme);
        });
    }, [mode, iframeSrc]);
  
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="URL Modal"
            className="scheCallModal"
            ariaHideApp={false}
        >
            {!iframeSrc ? (
                <HalfCircleSpinner
                    size={16}
                    style={{
                        display: "inline-block",
                        marginLeft: "10px",
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                />
                
            ):(
                <Iframe
                    url={iframeSrc}
                    width="100%"
                    height="100%"
                    display="block"
                />
            )}
            <button className={`clsBtn ${mode}`} onClick={onRequestClose}><span className='fa-solid fa-xmark'></span></button>
        </Modal>
    );
}

export default ScheduleCall