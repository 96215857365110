import React, { useState, useEffect } from "react";
import './header.css';
import Logo from '../../../assets/images/logo.svg';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [menuActive, setIsMenuActive] = useState(false);

    const openMobileMenu = () => {
		setIsMenuActive(!menuActive);
		document.body.classList.add('mobile-menu-open');
	};

	const hideMobileMenu = () => {
		setIsMenuActive(!menuActive);
		document.body.classList.remove('mobile-menu-open');
	};

    useEffect(() => {
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );

        const element = document.getElementById(href);
        const scrollToSectionWithDelay = () => {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        };
        setTimeout(scrollToSectionWithDelay, 100);
    }, []);

  	return (
        <div className="phStickyWrap">
            <header id="pageHeader">
				<div className="topBar fontPoppins txtCenter clrTheme">
                    <span>Empowering SaaS Growth through Expert Development.</span>
				</div>
                <div className="mainHeader">
                    <nav className="navBar">
                        <div className="collapseWrap">
                            <ul className="navbarNav">
                                <li className="navItem">
                                    <NavLink exact to="/about-us" activeClassName="active">
                                        About omelatte
                                    </NavLink>
                                </li>
                                <li className="navItem">
                                    {/* <Link 
                                        offset={-70} 
                                        smooth={true}
                                        to="services" 
                                        duration={500}
                                        className="navLink justForSpy"
                                        spy={true}
                                        href="services"
                                    >
                                        Services
                                    </Link> */}
                                    <a href="/#services" className="navLink">Services</a>
                                </li>
                                <li className="navItem">
                                    <a href="/#our-work" className="navLink">Our Work</a>
                                    {/* <NavLink exact to="/case-study" activeClassName="active">
                                        Case Study
                                    </NavLink> */}
                                </li>
                            </ul>
                            <a href="/pricing" className="btn btnPrimary hdBtn">Hire Omelatte <span className="icn icn-arrow-up-right"></span></a>
                            <button 
                                className="navbarTogglerClose" 
                                type="button"
                                onClick={() => {
                                    hideMobileMenu()
                                }}
                            >
                                <span className="hidden"></span>
                            </button>
                        </div>
                        <button 
                            className="navbarToggler" 
                            type="button"
                            onClick={() => {
                                openMobileMenu()
                            }}
                        >
                            <span className="togglerIcon"></span>
                            <div className="popupOverlay"></div>
                        </button>
                    </nav>
                    <div className="mainLogo">
                        <a href="/"><img src={Logo} className="imgFluid" alt="Omelatte" /></a>
                    </div>
                    <a href="/pricing" className="btn btnPrimary hdBtn">Hire Omelatte <span className="icn icn-arrow-up-right"></span></a>
                </div>
            </header>
        </div>
  	);
};

export default Header;