import React, { useState, useEffect } from 'react';
import Img01 from '../../assets/images/img14.jpg';
import Img02 from '../../assets/images/img15.jpg';
import Img03 from '../../assets/images/img16.jpg';
import Img04 from '../../assets/images/img17.jpg';
import ImgAvatar from '../../assets/images/img05.svg';
import ScheduleCall from "../../components/scheduleCall";
import Fancybox from '../../components/lightBox';

const CaseStudy = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Case Study | Omelatte";  
	}, []);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <section className='caseStudyDetail'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <strong className='postTag'>Web Design</strong>
                        <span className='postTpeTxt'>Corporate website</span>
                        <h1 className='h1 pstTitle'>Intelliceed</h1>
                        <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                    </header>
                    <div className='ftrImg bgGradiantGray bdrGradiant gradGray'>
                        <div className='imgWrap'>
                            <img src={Img01} className='imgFluid' alt="image description"/>
                        </div>
                    </div>
                    <div className='decpWrap'>
                        <div className='omlRow'>
                            <div className='omlCol csdCol40'>
                                <div className='contntWrap'>
                                    <div className='hdrWrap'>
                                        <h2 className='h2'>
                                            Bringing an Established<span className='txtOutline dBlock'> San Francisco</span>
                                        </h2>
                                        <strong className="txtTag txtUppercase">Tech Investment Firm</strong>
                                        <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                        <strong className='subTlte'>Result</strong>
                                        <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                        <ul className='ftrList'>
                                            <li>5 User Flows</li>
                                            <li>Prototypes</li>
                                            <li>Design of 30 + Screens</li>
                                            <li>Figma files with all assets</li>
                                        </ul>
                                    </div>
                                    <article className='contactWidget bgGradiantGray bdrGradiant gradGray fontPoppins'>
                                        <div className='wrapInner'>
                                            <div className='icnWrap'>
                                                <img src={ImgAvatar} className='imgFluid' alt="image description" />
                                            </div>
                                            <h3 className='h1'>Free 30-Minute Consultation</h3>
                                            <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form. And don't forget to catch us at Gitex Dubai - booth P27 H26, where we can explore the possibilities in person.</p>
                                            <button onClick={openModal} className='schDisLink txtUppercase'>Schedule discovery call</button>
                                            <ScheduleCall
                                                isOpen={isModalOpen}
                                                onRequestClose={closeModal}
                                            />
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className='omlCol csdCol60'>
                                <div className='imgGaleryWrap'>
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                                transition: "classic"
                                            },
                                            backdropClick: false
                                        }}
                                    >
                                        <a data-fancybox="gallery" className='imgWrap' href={Img02}>
                                            <img src={Img02} className='imgFluid' alt="image description"/>
                                        </a>
                                        <a data-fancybox="gallery" className='imgWrap' href={Img03}>
                                            <img src={Img03} className='imgFluid' alt="image description"/>
                                        </a>
                                        <a data-fancybox="gallery" className='imgWrap' href={Img04}>
                                            <img src={Img04} className='imgFluid' alt="image description"/>
                                        </a>
                                    </Fancybox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CaseStudy;