import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Cloud Hosting | Omelatte";  
	}, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>Cloud </span>
                            Hosting
                        </h1>
                        <p>Secure and scalable cloud solutions.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Reliable Cloud Infrastructure & Hosting</h2>
                            <div className='decpWrap'>
                                <p>Our cloud hosting service ensures the secure and scalable operation of your digital infrastructure. We provide a dependable cloud environment to ensure your applications run seamlessly and efficiently.</p>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
                            <ul className='ftrList'>
                                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                                <li><span>Security:</span> Robust data protection and compliance.</li>
                                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;