import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../src/assets/css/reset.css";
import "../src/assets/css/main.css";
import Layout from "./components/layout-components/layout";
import Home from "./pages/home";
import ContactUs from "./pages/contact-us";
import Pricing from "./pages/pricing";
import CaseStudy from "./pages/case-study";
import AboutUs from "./pages/about-us";
// import ServiceDetail from "./pages/services-detail";
import RecentWorks from "./pages/recent-works";
import NotFound from "./pages/404";
import SoftwareDevelopment from "./pages/services/software-development";
import MVPDevelopment from "./pages/services/mvp-development";
import CloudHosting from "./pages/services/cloud-hosting";
import MobileApps from "./pages/services/mobile-apps";
import SupportAndMaintenance from "./pages/services/support-and-maintenance";
import UxUiDesign from "./pages/services/ux-ui-design";

function App() {
	return (
		<div id="pageWrapper">
			<Router forceRefresh={true}>
				<Switch>
					<Layout>
						<Route exact path="/" component={Home}></Route>
						<Route exact path="/home" component={Home}></Route>
						<Route exact path="/contact-us" component={ContactUs}></Route>
						<Route exact path="/pricing" component={Pricing}></Route>
						<Route exact path="/case-study" component={CaseStudy}></Route>
						<Route exact path="/about-us" component={AboutUs}></Route>
						{/* <Route exact path="/service-detail" component={ServiceDetail}></Route> */}
						<Route exact path="/recent-works" component={RecentWorks}></Route>
						<Route exact path="/services/software-development" component={SoftwareDevelopment}></Route>
						<Route exact path="/services/mvp-development" component={MVPDevelopment}></Route>
						<Route exact path="/services/cloud-hosting" component={CloudHosting}></Route>
						<Route exact path="/services/mobile-apps" component={MobileApps}></Route>
						<Route exact path="/services/support-and-maintenance" component={SupportAndMaintenance}></Route>
						<Route exact path="/services/ux-ui-design" component={UxUiDesign}></Route>
						{/* <Route component={NotFound}></Route> */}
					</Layout>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
