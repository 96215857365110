import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "UX/UI Design | Omelatte";  
	}, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>UX/UI </span>
                            Design
                        </h1>
                        <p>Elevating digital user experiences.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Elevating Digital User Experiences</h2>
                            <div className='decpWrap'>
                                <p>Our UX/UI design service is all about creating exceptional experiences for your users. We focus on captivating design and user-centric interfaces to enhance engagement and satisfaction.</p>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Our Approach to UX/UI Design</h2>
                            <ul className='ftrList'>
                                <li><span>User-Centric:</span> Prioritizing user needs and preferences.</li>
                                <li><span>Visual Appeal:</span> Crafting stunning and visually appealing designs</li>
                                <li><span>Usability:</span> Ensuring intuitive and efficient interaction.</li>
                                <li><span>Brand Identity:</span> Aligning designs with your brand's identity</li>
                                <li><span>Testing & Iteration:</span> Continuously improving the user experience.</li>
                                <li>Our approach to UX/UI design is centered on creating interfaces that resonate with users and drive positive interactions.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;