import React, { useState, useEffect } from 'react';
import ImgCalenderBooking from '../../assets/images/img04.svg';
import ScheduleCall from "../../components/scheduleCall";
import ContactForm from "../../components/contactForm";

const ContactUs = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Contact Us | Omelatte";  
	}, []);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <section className='contactUsSection'>
            <div className='container'>
                <div className='omlRow'>
                    <div className='omlCol csCol40'>
                        <article className='contactWidget fontPoppins txtCenter'>
                            <div className='imgWrap'>
                                <img src={ImgCalenderBooking} className='imgFluid' alt="image description" />
                            </div>
                            <div className='descWrap'>
                                <span className='circle'></span>
                                <h2 className='h2'>Free 30-Minute Consultation</h2>
                                <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form. And don't forget to catch us at Gitex Dubai - booth P27 H26, where we can explore the possibilities in person.</p>
                                <button onClick={openModal} className='schDisLink txtUppercase'>Schedule discovery call</button>
                                <ScheduleCall
                                    isOpen={isModalOpen}
                                    onRequestClose={closeModal}
                                />
                            </div>
                        </article>
                    </div>
                    <div className='omlCol csCol60'>
                        <header className='sectionHeader'>
                            <h1 className='h1'>
                                <span className='txtOutline dBlock'>Let's Talk </span>
                                About You <span className='icnCircle icn-arrow-up-right'></span>
                            </h1>
                        </header>
                        <ContactForm/>
                        <div className='cntctBtmRow'>
                            <div>
                                <span className='emlTxt fontPoppins'>Or email us at</span>
                                <a href="mailto:Hello@Omelatte.com" className='emailLink'>Hello@Omelatte.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;