import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import ScheduleCall from "../../components/scheduleCall";
import ContactForm from "../../components/contactForm";
import Img01 from '../../assets/images/img01.jpg';
import Img02 from '../../assets/images/img02.jpg';
import Img03 from '../../assets/images/img18.jpg';
import Img04 from '../../assets/images/img19.jpg';
import Img05 from '../../assets/images/img20.jpg';
import Img06 from '../../assets/images/img21.jpg';
import PortfolioBg from '../../assets/images/img22.png';
import ServicesBg from '../../assets/images/img23.png';
import Icn01 from '../../assets/images/ico01.svg';
import Icn02 from '../../assets/images/ico02.svg';
import Icn03 from '../../assets/images/ico03.svg';
import Logo from '../../assets/images/logo.svg';
import ImgWaves from '../../assets/images/image-waves.svg';
import IcnCursor from '../../assets/images/logo-cursor.svg';

const Home = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Home | Omelatte";  
	}, []);

    var settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        speed: 1500,
        autoplaySpeed: 7000,
        autoplay: true,
        adaptiveHeight: true
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };


    const sectionRef = useRef(null);
      
    useEffect(() => {
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        const section = sectionRef.current;
    
        if (element && section) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5, // Adjust this threshold as needed
            };
    
            const callback = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        console.log('The section is in the viewport')
                        // The section is in the viewport
                        // You can activate the link or perform other actions here
                    } else {
                        console.log('The section is out the viewport')
                        // The section is out of the viewport
                    }
                });
            };
    
            const observer = new IntersectionObserver(callback, options);
            observer.observe(section);
    
            // Clean up the observer when the component unmounts
            return () => {
                observer.unobserve(section);
            };
        }
    },[]);

    return (
        <>
            <section className="bannerSection">
                <div className='container'>
                    <div className='omlRow'>
                        <div className='omlCol bnrCol70'>
                            <header className='sectionHeader'>
                                <h1 className='h1 txtUppercase'>
                                    TURNING STARTUP 
                                    <span className='txtOutline'> DREAMS <span className='icn icn-arrow-up-right'></span></span>
                                    INTO CODE
                                </h1>
                                <strong className='txtTag txtUppercase'>YOUR STARTUP, OUR PASSION</strong>
                            </header>
                        </div>
                        <div className='omlCol bnrCol30'>
                            <div className='bnrWidget'>
                                <div className='wdgHolder'>
                                    <span className='gradLne'></span>
                                    <span className='wdgTag bgYellow'>SaaS</span>
                                    <span className='gradLne'></span>
                                    <span className='gradLne'></span>
                                    <span className='wdgTag bgGreen'>
                                        <img src={IcnCursor} className='imgFluid flotingIcn' alt="image description"/>
                                        Development
                                    </span>
                                    <span className='gradLne'></span>
                                    <span className='gradLne'></span>
                                    <span className='wdgTag bgOrange'>Creative</span>
                                </div>
                                <img src={ImgWaves} className='imgFluid flotingObject' alt="image description"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <aside className='callBookAside fontPoppins'>
                <div className='container'>
                    <a href="/pricing" className='estCstBtn'>
                        <span className='lgoWrap'>
                            <img src={Logo} className='imgFluid' alt="Omelatte" />
                        </span>
                        <span className='planTxt'>Meet us at Gitex, Booth P27 H26.</span>
                    </a>
                </div>
            </aside>
            <section className='secvicesSection'>
                <div className='container'>
                    <div className='imgWrap txtCenter'>
                        <img src={Img01} className='imgFluid' alt="image description" />
                    </div>
                    <div ref={sectionRef}>
                        <header className='sectionHeader txtCenter'>
                            <span id="services" className='scrollRef'></span>
                            <h1 className='h1 txtUppercase'>
                                <span className='txtOutline'>Our Comprehensive </span>
                                Service Offerings
                            </h1>
                        </header>
                        <ul className='servcList'>
                            <li>
                                <article className='srvcWidget'>
                                    <strong className='srvTitle'>Software Development</strong>
                                    <p>Crafting custom software solutions.</p>
                                    <a href="/services/software-development" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                                </article>
                            </li>
                            <li>
                                <article className='srvcWidget'>
                                    <strong className='srvTitle'>MVP Development</strong>
                                    <p>Accelerating your product's journey to market.</p>
                                    <a href="/services/mvp-development" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                                </article>
                            </li>
                            <li>
                                <article className='srvcWidget'>
                                    <strong className='srvTitle'>UX/UI Design</strong>
                                    <p>Enhancing digital user experiences.</p>
                                    <a href="/services/ux-ui-design" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                                </article>
                            </li>
                            <li>
                                <article className='srvcWidget'>
                                    <strong className='srvTitle'>Cloud Hosting</strong>
                                    <p>Secure and scalable cloud solutions.</p>
                                    <a href="/services/cloud-hosting" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                                </article>
                            </li>
                            <li>
                                <article className='srvcWidget'>
                                    <strong className='srvTitle'>Mobile Apps</strong>
                                    <p>Engaging users across various devices.</p>
                                    <a href="/services/mobile-apps" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                                </article>
                            </li>
                            <li>
                                <article className='srvcWidget'>
                                    <strong className='srvTitle'>Support & Maintenance</strong>
                                    <p>Sustaining your ongoing success.</p>
                                    <a href="/services/support-and-maintenance" className='readMoreLnk'>Read More <span className='icn icn-arrow-up-right'></span></a>
                                </article>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='bgImg' style={{backgroundImage: `url(${ServicesBg})`}}></div>
            </section>
            <section className='gtYrAppSection'>
                <div className='container'>
                    <div className='sctnTagElem'>
                        <span>Not sure what you need? GET A FREE</span>
                        <strong onClick={openModal} className='txtTag txtUppercase'>Consultation</strong>
                    </div>
                    <div className='omlRow'>
                        <div className='omlCol gyaCol40'>
                            <header className='sectionHeader'>
                                <h1 className='h1 txtUppercase'>
                                    <span className='txtOutline'>GET YOUR MVP </span>
                                    DEVELOPED IN NO TIME
                                </h1>
                            </header>
                        </div>
                        <div className='omlCol gyaCol60'>
                            <ul className='appFtrList'>
                                <li>
                                    <article className='appFtrWdgt'>
                                        <div className='imgWrap'>
                                            <img src={Icn01} className='imgFluid' alt="image description"/>
                                        </div>
                                        <div className='descWrap'>
                                            <strong className='titleTxt'>On-Time, Every Time</strong>
                                            <p>Experience lightning-fast MVP delivery with us – your MVP will be ready in no time.</p>
                                        </div>
                                    </article>
                                </li>
                                <li>
                                    <article className='appFtrWdgt'>
                                        <div className='imgWrap'>
                                            <img src={Icn02} className='imgFluid' alt="image description"/>
                                        </div>
                                        <div className='descWrap'>
                                            <strong className='titleTxt'>Uncompromising Quality</strong>
                                            <p>We prioritize top-notch quality, adhering to the highest standards. Committed to delivering excellence.</p>
                                        </div>
                                    </article>
                                </li>
                                <li>
                                    <article className='appFtrWdgt'>
                                        <div className='imgWrap'>
                                            <img src={Icn03} className='imgFluid' alt="image description"/>
                                        </div>
                                        <div className='descWrap'>
                                            <strong className='titleTxt'>Transparent & Fair Pricing</strong>
                                            <p>Subscribe to our fixed-price model. Simply add your tasks, and we'll handle them one by one, ensuring transparent and fair pricing.</p>
                                        </div>
                                    </article>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='blogSection'>
                <span className='flotScrlRef' id="our-work"></span>
                <div className='container'>
                    <header className='sectionHeader'>
                        <strong className='txtTag txtUppercase'>Made by Omelatte</strong>
                        <h1 className='h1 txtUppercase'>
                            <span className='txtOutline'>Results </span>
                            Delivered
                        </h1>
                    </header>
                    <div className='omlRow wthOtSlider'>
                        <div className='omlCol bgsCol45'>
                            <article className='blogPost' data-feature="Latest">
                                <div className='imgWrap'>
                                    <a href='#'><img src={Img02} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Shopify Apps</span>
                                        <ul className='tagList'>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Croonials</a></h1>
                                    <p>Innovative Shopify apps to enhance your e-commerce experience.</p>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol bgsCol55'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href="#"><img src={Img03} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Shopify Apps</span>
                                        <ul className='tagList'>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">SlesparkApps</a></h1>
                                    <p>Empowering your Shopify store with cutting-edge apps.</p>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className='bgSliderWrap'>
                        <Slider {...settings}>
                            <div>
                                <article className='blogPost'>
                                    <div className='imgWrap'>
                                        <a href="#"><img src={Img06} className='imgFluid' alt="image description"/></a>
                                    </div>
                                    <div className='descpWrap'>
                                        <div className='infoWrap'>
                                            <span className='blgCat'>Find Your Forever Pet</span>
                                            <ul className='tagList'>
                                                <li><a href="">Branding</a></li>
                                                <li className='active'><a href="">Web Design</a></li>
                                            </ul>
                                        </div>
                                        <h1 className='h1'><a href="#">PetBond</a></h1>
                                        <p>Discover dogs for sale in Ireland and find your perfect pet companion.</p>
                                    </div>
                                </article>
                            </div>
                            <div>
                                <article className='blogPost'>
                                    <div className='imgWrap'>
                                        <a href="#"><img src={Img04} className='imgFluid' alt="image description"/></a>
                                    </div>
                                    <div className='descpWrap'>
                                        <div className='infoWrap'>
                                            <span className='blgCat'>Vehicle Tracking</span>
                                            <ul className='tagList'>
                                                <li className='active'><a href="">Web Design</a></li>
                                            </ul>
                                        </div>
                                        <h1 className='h1'><a href="#">Connexis</a></h1>
                                        <p>Specializing in GPS satellite vehicle tracking systems.</p>
                                    </div>
                                </article>
                            </div>
                            <div>
                                <article className='blogPost' data-feature="Latest Project">
                                    <div className='imgWrap'>
                                        <a href="#"><img src={Img05} className='imgFluid' alt="image description"/></a>
                                    </div>
                                    <div className='descpWrap'>
                                        <div className='infoWrap'>
                                            <span className='blgCat'>Solutions for Dentists</span>
                                            <ul className='tagList'>
                                                <li className='active'><a href="">Web Design</a></li>
                                            </ul>
                                        </div>
                                        <h1 className='h1'><a href="#">Membersy</a></h1>
                                        <p>Revolutionizing dental membership plans for DSOs and dentists.</p>
                                    </div>
                                </article>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className='bgImg' style={{backgroundImage: `url(${PortfolioBg})`}}></div>
            </section>
            <section className='hwItWrkSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter fontPoppins'>
                        <h1 className='h1'>
                            <span className='txtOutline txtUppercase dBlock'>How it works </span>
                            Unveiling the Process
                        </h1>
                        <p>Experience a hassle-free approach to software development.</p>
                    </header>
                    <ul className='hiwList txtCenter'>
                        <li>
                            <article className='hiwWidget'>
                                <span className='hiwCounter'></span>
                                <strong className='hiwTitle txtUppercase dBlock'>Subscribe to start</strong>
                                <p>Begin by subscribing to our plan. <a href="/pricing">Subscribe</a></p>
                            </article>
                        </li>
                        <li>
                            <article className='hiwWidget'>
                                <span className='hiwCounter'></span>
                                <strong className='hiwTitle txtUppercase dBlock'>Access Jira & Slack</strong>
                                <p>Gain access to Linear Board and Slack.</p>
                            </article>
                        </li>
                        <li>
                            <article className='hiwWidget'>
                                <span className='hiwCounter'></span>
                                <strong className='hiwTitle txtUppercase dBlock'>Add Tasks to Jira</strong>
                                <p>Achieve sequential task completion by adding tasks to the Linear Board.</p>
                            </article>
                        </li>
                        <li>
                            <article className='hiwWidget'>
                                <span className='hiwCounter'></span>
                                <strong className='hiwTitle txtUppercase dBlock'>Slack Communication</strong>
                                <p>Enjoy seamless communication through Slack - questions are always welcome, and meetings are minimal.</p>
                            </article>
                        </li>
                    </ul>
                </div>
            </section>
            <section className='contactSection'>
                <div className='container'>
                    <div className='omlRow'>
                        <div className='omlCol csCol40'>
                            <header className='sectionHeader'>
                                <h1 className='h1 txtUppercase'>
                                    <span className='txtOutline dBlock'>Submit 
                                        <span className='txtTag'>Submit Awesome</span>
                                    </span>
                                    Your Idea
                                </h1>
                            </header>
                            <article className='contactWidget fontPoppins'>
                                <span className='circle'></span>
                                <h2 className='h2'>Free 30-Minute Consultation</h2>
                                <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form. And don't forget to catch us at Gitex Dubai - booth P27 H26, where we can explore the possibilities in person.</p>
                                <button onClick={openModal} className='schDisLink txtUppercase'>Schedule discovery call</button>
                            </article>
                        </div>
                        <div className='omlCol csCol60'>
                            <ContactForm/>
                            <span className='emlTxt fontPoppins'>Or email us at</span>
                            <a href="mailto:Hello@Omelatte.com" className='emailLink'>Hello@Omelatte.com</a>
                        </div>
                    </div>
                </div>
            </section>
            <ScheduleCall
                isOpen={isModalOpen}
                onRequestClose={closeModal}
            />
        </>
    );
}

export default Home;