import React, { useState, useEffect } from 'react';
import Img01 from '../../assets/images/img02.jpg';
import Img02 from '../../assets/images/img18.jpg';
import ScheduleCall from "../../components/scheduleCall";
import ContactForm from "../../components/contactForm";

const RecentWorks = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Recent Works | Omelatte";  
	}, []);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <section className='blogSection recentWorkSection'>
                <div className='container'>
                    <header className='sectionHeader'>
                        <h1 className='h1 txtUppercase'>
                            <span className='txtOutline'>Recent </span>
                            Works Delivered
                        </h1>
                    </header>
                    <div className='omlRow'>
                        <div className='omlCol bgsCol45'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href='#'><img src={Img01} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Retail, Sports & Fitness</span>
                                        <ul className='tagList'>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Gymthing</a></h1>
                                    <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                    <strong className='subTlte'>Result</strong>
                                    <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                    <ul className='ftrList'>
                                        <li>5 User Flows</li>
                                        <li>Prototypes</li>
                                        <li>Design of 30 + Screens</li>
                                        <li>Figma files with all assets</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol bgsCol55'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href="#"><img src={Img02} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Tech Investment</span>
                                        <ul className='tagList'>
                                            <li><a href="">Branding</a></li>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Top Corner</a></h1>
                                    <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                    <strong className='subTlte'>Result</strong>
                                    <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                    <ul className='ftrList'>
                                        <li>5 User Flows</li>
                                        <li>Prototypes</li>
                                        <li>Design of 30 + Screens</li>
                                        <li>Figma files with all assets</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol bgsCol55'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href="#"><img src={Img02} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Tech Investment</span>
                                        <ul className='tagList'>
                                            <li><a href="">Branding</a></li>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Distance Learning App</a></h1>
                                    <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                    <strong className='subTlte'>Result</strong>
                                    <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                    <ul className='ftrList'>
                                        <li>5 User Flows</li>
                                        <li>Prototypes</li>
                                        <li>Design of 30 + Screens</li>
                                        <li>Figma files with all assets</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol bgsCol45'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href='#'><img src={Img01} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Retail, Sports & Fitness</span>
                                        <ul className='tagList'>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Gymthing</a></h1>
                                    <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                    <strong className='subTlte'>Result</strong>
                                    <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                    <ul className='ftrList'>
                                        <li>5 User Flows</li>
                                        <li>Prototypes</li>
                                        <li>Design of 30 + Screens</li>
                                        <li>Figma files with all assets</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol bgsCol45'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href='#'><img src={Img01} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Retail, Sports & Fitness</span>
                                        <ul className='tagList'>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Gymthing</a></h1>
                                    <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                    <strong className='subTlte'>Result</strong>
                                    <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                    <ul className='ftrList'>
                                        <li>5 User Flows</li>
                                        <li>Prototypes</li>
                                        <li>Design of 30 + Screens</li>
                                        <li>Figma files with all assets</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol bgsCol55'>
                            <article className='blogPost'>
                                <div className='imgWrap'>
                                    <a href="#"><img src={Img02} className='imgFluid' alt="image description"/></a>
                                </div>
                                <div className='descpWrap'>
                                    <div className='infoWrap'>
                                        <span className='blgCat'>Tech Investment</span>
                                        <ul className='tagList'>
                                            <li><a href="">Branding</a></li>
                                            <li className='active'><a href="">Web Design</a></li>
                                        </ul>
                                    </div>
                                    <h1 className='h1'><a href="#">Top Corner</a></h1>
                                    <p>This app has been expertly designed to provide acomprehensive directory listing.</p>
                                    <strong className='subTlte'>Result</strong>
                                    <p>Perfect solution for locating and learning more about hotels, restaurants, and other local establishments.</p>
                                    <ul className='ftrList'>
                                        <li>5 User Flows</li>
                                        <li>Prototypes</li>
                                        <li>Design of 30 + Screens</li>
                                        <li>Figma files with all assets</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section className='contactSection'>
                <div className='container'>
                    <div className='omlRow'>
                        <div className='omlCol csCol40'>
                            <header className='sectionHeader'>
                                <h1 className='h1 txtUppercase'>
                                    <span className='txtOutline dBlock'>Submit 
                                        <span className='txtTag'>Submit Awesome</span>
                                    </span>
                                    Your Idea
                                </h1>
                            </header>
                            <article className='contactWidget fontPoppins'>
                                <span className='circle'></span>
                                <h2 className='h2'>Free 30-Minute Consultation</h2>
                                <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form. And don't forget to catch us at Gitex Dubai - booth P27 H26, where we can explore the possibilities in person.</p>
                                <button onClick={openModal} className='schDisLink txtUppercase'>Schedule discovery call</button>
                                <ScheduleCall
                                    isOpen={isModalOpen}
                                    onRequestClose={closeModal}
                                />
                            </article>
                        </div>
                        <div className='omlCol csCol60'>
                            <ContactForm/>
                            <span className='emlTxt fontPoppins'>Or email us at</span>
                            <a href="mailto:Hello@Omelatte.com" className='emailLink'>Hello@Omelatte.com</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RecentWorks;