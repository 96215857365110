import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Support & Maintenance | Omelatte";  
	}, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>Support </span>
                            & Maintenance
                        </h1>
                        <p>Ongoing care for your success.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Reliable Support & Maintenance Services</h2>
                            <div className='decpWrap'>
                                <p>Our commitment to your success goes beyond project launch. We offer ongoing support and maintenance services to keep your solutions running smoothly and up-to-date.</p>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Why Invest in Support & Maintenance?</h2>
                            <ul className='ftrList'>
                                <li><span>Performance Optimization:</span> Ensure your software operates at its best.</li>
                                <li><span>Security Updates:</span> Protect against vulnerabilities and threats.</li>
                                <li><span>Scalability:</span> Adapt your solutions to changing business needs</li>
                                <li><span>User Support:</span> Assist users with technical issues and inquiries.</li>
                                <li><span>Continuous Improvement:</span> Keep your software current and competitive.</li>
                                <li>Support and maintenance are essential investments in the long-term success and performance of your digital solutions.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;