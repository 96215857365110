import React from "react";
import './footer.css';
import Logo from '../../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';

const Footer = () => {
	const getCurrentYear = () => {
		return new Date().getFullYear();
	};

  	return (
		<div className="footerArea">
			<div className="container">
				<footer id="pageFooter">
                    <div className="ftRow">
						<div className="ftCol ftCol35">
							<article className="ftWidget">
								<div className="ftLogo">
									<a href="/"><img src={Logo} className="imgFluid" alt="Omelatte" /></a>
									<p>You didn't expect SaaS to be this seamless.</p>
								</div>
								<div className="cpyTxt">
									&copy; {getCurrentYear()}, All Rights Reserved by <a href="https://www.omelatte.com/">omelatte.com</a>
								</div>
							</article>
						</div>
						<div className="ftCol ftCol65">
							<nav className="ftNav">
								<ul className="ftList">
									<li>
										<NavLink exact to="/services/software-development" activeClassName="active">
											Software Development
										</NavLink>
									</li>
									<li>
										<NavLink exact to="/services/mvp-development" activeClassName="active">
											MVP Development
										</NavLink>
									</li>
									<li>
										<NavLink exact to="/services/ux-ui-design" activeClassName="active">
											UX/UI Design
										</NavLink>
									</li>
									<li>
										<NavLink exact to="/services/cloud-hosting" activeClassName="active">
											Cloud Hosting
										</NavLink>
									</li>
									<li>
										<NavLink exact to="/services/mobile-apps" activeClassName="active">
											Mobile Apps
										</NavLink>
									</li>
									<li>
										<NavLink exact to="/services/support-and-maintenance" activeClassName="active">
											Support & Maintenance
										</NavLink>
									</li>
								</ul>
								<ul className="ftList">
									<li>
										<NavLink exact to="/about-us" activeClassName="active">
											About
										</NavLink>
									</li>
									{/* <li>
										<NavLink exact to="/recent-works" activeClassName="active">
											Recent Work
										</NavLink>
									</li> */}
									<li>
										<NavLink exact to="/pricing" activeClassName="active">
											Pricing
										</NavLink>
									</li>
									<li>
										<NavLink exact to="/contact-us" activeClassName="active">
											Get in Touch
										</NavLink>
									</li>
								</ul>
							</nav>
						</div>
					</div>
				</footer>
			</div>
		</div>
  	);
};

export default Footer;