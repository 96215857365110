import React, { useState, useEffect } from 'react';
import ImgAvatar from '../../assets/images/img05.svg';
import ImgAddOn from '../../assets/images/img06.jpg';
import ScheduleCall from "../../components/scheduleCall";

const Pricing = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Pricing | Omelatte";  
	}, []);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <section className='pricingSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>Software <span className='icnCircle icn-arrow-up-right noOutline'></span> Development as a </span>
                            Monthly Subscription
                        </h1>
                        <p>Our mission is to facilitate seamless access to contemporary software development with utmost ease.</p>
                    </header>
                    <div className='omlRow'>
                        <div className='omlCol omlCol33'>
                            <article className='planArticle'>
                                <div className='plnInnerWrap'>
                                    <div className='planHead'>
                                        <h1 className='h1'>
                                            Monthly Plan
                                            <strong className='sbTtle'>Pay monthly</strong>
                                        </h1>
                                        <h2 className='plnPrice'>
                                            3,500$/mo
                                            <strong className='sbTtle'>Plus VAT. Pause or cancel anytime</strong>
                                        </h2>
                                    </div>
                                    <div className='planBody'>
                                        <h3 className='h3'>What’s included:</h3>
                                        <ul className='ftrList'>
                                            <li>One request at a time</li>
                                            <li>Unlimited brands / companies</li>
                                            <li>Unlimited users</li>
                                            <li>Unlimited stock photos via Shutterstock</li>
                                            <li>Easy credit-card payments</li>
                                            <li>Pause or cancel anytime</li>
                                        </ul>
                                    </div>
                                    <div className='planFoot'>
                                        <button type='button' onClick={openModal} className='btn btnPrimary txtUppercase dBlock'>Get Started</button>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol omlCol33'>
                            <article className='planArticle' data-type="Popular" data-count="2">
                                <div className='plnInnerWrap'>
                                    <div className='planHead'>
                                        <h1 className='h1'>
                                            Every 6 Month Plan
                                            <strong className='sbTtle'>Save $250 per month by paying quarterly</strong>
                                        </h1>
                                        <h2 className='plnPrice'>
                                            3,250$/mo
                                            <strong className='sbTtle'>Plus VAT. Pause or cancel anytime</strong>
                                        </h2>
                                    </div>
                                    <div className='planBody'>
                                        <h3 className='h3'>What’s included:</h3>
                                        <ul className='ftrList'>
                                            <li>One request at a time</li>
                                            <li>Unlimited brands / companies</li>
                                            <li>Unlimited users</li>
                                            <li>Unlimited stock photos via Shutterstock</li>
                                            <li>Easy credit-card payments</li>
                                            <li>Pause or cancel anytime</li>
                                        </ul>
                                    </div>
                                    <div className='planFoot'>
                                        <button type='button' onClick={openModal} className='btn btnPrimary txtUppercase dBlock'>Get Started</button>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol omlCol33'>
                            <article className='planArticle'>
                                <div className='plnInnerWrap'>
                                    <div className='planHead'>
                                        <h1 className='h1'>
                                            Yearly Plan
                                            <strong className='sbTtle'>Unlocking a Superior Approach</strong>
                                        </h1>
                                        <h2 className='plnPrice'>
                                            3,000$/mo
                                            <strong className='sbTtle'>Plus VAT. Pause or cancel anytime</strong>
                                        </h2>
                                    </div>
                                    <div className='planBody'>
                                        <h3 className='h3'>What’s included:</h3>
                                        <ul className='ftrList'>
                                            <li>One request at a time</li>
                                            <li>Unlimited brands / companies</li>
                                            <li>Unlimited users</li>
                                            <li>Unlimited stock photos via Shutterstock</li>
                                            <li>Easy credit-card payments</li>
                                            <li>Pause or cancel anytime</li>
                                        </ul>
                                    </div>
                                    <div className='planFoot'>
                                        <button type='button' onClick={openModal} className='btn btnPrimary txtUppercase dBlock'>Get Started</button>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section className='addOnSection'>
                <div className='container'>
                    <div className='omlRow'>
                        <div className='omlCol omlCol33'>
                            <article className='contactWidget bgGradiantGray bdrGradiant gradGray fontPoppins'>
                                <div className='wrapInner'>
                                    <div className='icnWrap'>
                                        <img src={ImgAvatar} className='imgFluid' alt="image description" />
                                    </div>
                                    <h1 className='h1'>Free 30-Minute Consultation</h1>
                                    <p>Have a new project in mind? Schedule a 30-minute discovery call or fill out the quick form. And don't forget to catch us at Gitex Dubai - booth P27 H26, where we can explore the possibilities in person.</p>
                                    <button onClick={openModal} className='schDisLink txtUppercase'>Schedule discovery call</button>
                                </div>
                            </article>
                        </div>
                        <div className='omlCol omlCol67'>
                            <article className='addOnWidget bgGradiantGray bdrGradiant gradBlue fontPoppins' onClick={openModal}>
                                <div className='wrapInner'>
                                    <div className='decpWrap'>
                                        <strong className='bdgeAddOn'>Add on</strong>
                                        <h1 className='h1'>Webflow Development</h1>
                                        <p>Unlock faster, better websites with Webflow development.</p>
                                        <span className='rqdTxt'></span>
                                        <h2 className='addOnPrice'>
                                            1,600$/mo
                                            <span className='prcTtl'>Plus VAT. Pause or cancel anytime</span>
                                        </h2>
                                    </div>
                                    <div className='imgWrap'>
                                        <img src={ImgAddOn} className='imgFluid' alt="image description" />
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <ScheduleCall
                isOpen={isModalOpen}
                onRequestClose={closeModal}
            />
        </>
    );
}

export default Pricing;