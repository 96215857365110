import axios from 'axios';
import React, { useState } from 'react';
import Logo from '../assets/images/logo-omelatte.svg';
import {HalfCircleSpinner} from 'react-epic-spinners';

const ContactForm = (props)  => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [estimatedBudget, setEstimatedBudget] = useState("");
    const [briefMessage, setBriefMessage] = useState("");
    const [errors, setErrors] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [apiResponse, setApiResponse] = useState('');

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!name) {
            isValid = false;
            errors['name'] = 'Please enter your Name.';
        }
        if (!email) {
            isValid = false;
            errors['email'] = 'Please enter your email Address.';
        }
        if (email) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,8}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                isValid = false;
                errors['email'] = 'Please enter valid Email Address.';
            }
        }
        if (!estimatedBudget) {
            isValid = false;
            errors['estimatedBudget'] = 'Please enter your Estimated Budget.';
        }
        if (!briefMessage) {
            isValid = false;
            errors['briefMessage'] = 'Please enter a brief message here.';
        }
        setErrors(errors);
        return isValid;
    };

    const resetForm = () => {
        setName('');
        setEmail('');
        setEstimatedBudget('');
        setBriefMessage('');
    };

    const submitForm = async (e) => {
        e.preventDefault();
		if (validateForm()) {
            setShowLoader(true)

            const requestBody = {
                name: name,
                email: email,
                budget: estimatedBudget,
                message: briefMessage,
                support_doc_category_id: 2,
                type: 'apps',
                title: 'ABC Title'
			};

            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('budget', estimatedBudget);
            formData.append('message', briefMessage);
            formData.append('support_doc_category_id', '2');
            formData.append('type', 'apps');
            formData.append('title', 'ABC Title');

            const headers = {
                'Content-Type': 'multipart/form-data',
                'token': process.env.REACT_APP_CONTACT_FORM_API_TOKEN,
            };

            try {
                const response = await axios.post(`${process.env.REACT_APP_CONTACT_FORM_API_URL}/doc/contact-form`, formData, { headers });
                if(response.status === 200) {
                    setShowLoader(false);
                    setApiResponse('Informationa successfully submitted!');
                    console.log(response.data.message)
                    resetForm();
                    setTimeout(() => {
                        setApiResponse('');
                    }, 4000);
                }
            } catch (error) {
                setShowLoader(false)
                console.error('Error sending email:', error);
            }
		}
	}

    return (
        <form className='contactForm' onSubmit={(event) => submitForm(event)}>
            <div className='omlRow'>
                <div className='omlCol csfCol50'>
                    <input 
                        type="text" 
                        className='formControl' 
                        name="name" 
                        placeholder='Your name' 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && (
                        <small className="formErrors">
                            {errors.name}
                        </small>
                    )}
                </div>
                <div className='omlCol csfCol50'>
                    <input 
                        type="email" 
                        className='formControl'
                        name="email" 
                        placeholder='Your email' 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                        <small className="formErrors">
                            {errors.email}
                        </small>
                    )}
                </div>
            </div>
            <div className='omlRow'>
                <div className='omlCol'>
                    <input 
                        type="text" 
                        className='formControl'
                        name="budget" 
                        placeholder='Estimated budget' 
                        value={estimatedBudget}
                        min="0"
                        onChange={(e) => setEstimatedBudget(e.target.value)}
                    />
                    {errors.estimatedBudget && (
                        <small className="formErrors">
                            {errors.estimatedBudget}
                        </small>
                    )}
                </div>
            </div>
            <div className='omlRow'>
                <div className='omlCol'>
                    <textarea 
                        type="text" 
                        className='formControl'
                        name="message" 
                        placeholder='Your mini brief' 
                        value={briefMessage}
                        onChange={(e) => setBriefMessage(e.target.value)}
                    ></textarea>
                    {errors.briefMessage && (
                        <small className="formErrors">
                            {errors.briefMessage}
                        </small>
                    )}
                </div>
            </div>
            <div className='btnRow'>
                <div className='btnWrap'>
                    <button 
                        type="submit" 
                        className='btn btnPrimary btnIcn fontPoppins btnSubmit txtUppercase'
                        disabled={showLoader}
                    >
                        <img src={Logo} className='imgFluid' alt="Omelatte" />
                        <span className='btnTxt'>Book a call with us</span>

                        {showLoader && (
                            <HalfCircleSpinner
                                size={16}
                                style={{
                                    display: "inline-block",
                                    marginLeft: "10px",
                                }}
                            />
                        )}
                    </button>
                    <span className='ectTxt fontPoppins'>Meet us at Gitex, Booth P27 H26.</span>
                </div>
                {apiResponse && (
                    <span className="successText">{apiResponse}</span>
                )}
            </div>
        </form>
    );
}

export default ContactForm