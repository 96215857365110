import React, { useState, useEffect } from 'react';
import ImgTeam01 from '../../assets/images/img07.png';
import ImgTeam02 from '../../assets/images/img08.png';
import ImgTeam03 from '../../assets/images/img09.png';
import ImgTeam04 from '../../assets/images/img10.png';
import ImgTeam05 from '../../assets/images/img11.png';
import ImgTeam06 from '../../assets/images/img12.png';
import ImgTeam07 from '../../assets/images/img13.png';

const AboutUs = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "About Us | Omelatte";  
	}, []);

    const dataArray = [
        {
            "id": 1,
            "title": "OUR SCOPE",
            "description": "Agile solutions for custom software development with top-tier design and innovation."
        },
        {
            "id": 2,
            "title": "LET'S DISCUSS",
            "description": "Expert programming, agile adaptation, client-centric discussions, and practical results you can trust."
        },
        {
            "id": 3,
            "title": "DESIGN & PROTOTYPE",
            "description": "Elevate your brand with impactful designs and prototypes, ensuring your success."
        },
        {
            "id": 4,
            "title": "DEVELOPMENT",
            "description": "Enhance your online presence with tailored front-end and back-end solutions for seamless success."
        }
    ];

    const [activeChild, setActiveChild] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveChild((prevActiveChild) => (prevActiveChild + 1) % 4);
        }, 7000);
  
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <section className='aboutUsSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>We are Dedicated to </span>
                            Problem Solving
                        </h1>
                        <strong className='clrTheme dBlock'>Company</strong>
                        <p>Our mission is to facilitate seamless access to contemporary software development with utmost ease.</p>
                        <ul className='wdpsList'>
                            <li>Quality delivery</li>
                            <li>Transparency</li>
                            <li>Revisions</li>
                            <li>Best Quote</li>
                        </ul>
                    </header>
                    <div className='aprTeamWrap fontPoppins'>
                        <div className='atBlock approachBlock bdrGradiant gradGray'>
                            <div className='atWrapInner'>
                                <h2 className='h2'>
                                    <strong className='abhTitle dBlock'>Our Approach</strong>
                                    Working the Smart Way
                                </h2>
                                <p>If your actions inspire others to dream more, learn more, do more, and become more, you are a leader.</p>
                                <ul className='aprList'>
                                    {dataArray.map((e, index) => (
                                        <li
                                            key={index}
                                            className={activeChild === index ? 'active' : ''}
                                        >
                                            <div className='aprWidgetWrap'>
                                                <h3 className='h3 txtUppercase'><span>{e.title}</span></h3>
                                                <p>{e.description}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                {/* <ul className='aprList'>
                                    <li>
                                        <div className='aprWidgetWrap'>
                                            <h3 className='h3 txtUppercase'><span>Our Scope</span></h3>
                                            <p>Your agile solution for custom software development with top-tier design and innovation.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='aprWidgetWrap'>
                                            <h3 className='h3 txtUppercase'><span>Let's Discuss</span></h3>
                                            <p>Expert programming, agile adaptation, client-centric discussions, and practical results for trust.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='aprWidgetWrap'>
                                            <h3 className='h3 txtUppercase'><span>Design & Prototype</span></h3>
                                            <p>Transform your brand with impactful designs and prototypes for guaranteed success.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='aprWidgetWrap'>
                                            <h3 className='h3 txtUppercase'><span>Development</span></h3>
                                            <p>Empower your online presence with tailored front-end and back-end solutions for seamless success.</p>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        <div className='atBlock teamBlock'>
                            <h2 className='h2'>
                                <strong className='abhTitle dBlock'>Team</strong>
                                Leadership & Business Development
                            </h2>
                            <p>Inspiring dreams, fostering growth, and delivering results.</p>
                            <div className='leadsWrap'>
                                <article className='leadTeamWidget'>
                                    <div className='imgWrap' data-position="center">
                                        <img src={ImgTeam07} className='imgFluid' alt="FAISAL FAROOQ" />
                                    </div>
                                    <div className='descWrap'>
                                        <h3 className='h3 txtUppercase'>
                                            FAISAL FAROOQ
                                            <strong className='desigTitle fontPoppins'>Chief Executive Officer</strong>
                                        </h3>
                                        <p>Faisal Farooq, a dynamic professional with 10+ years in DevOps and Business Development, excels in front-line team leadership, backed by certifications from AWS and Google.</p>
                                    </div>
                                </article>
                                <article className='leadTeamWidget'>
                                    <div className='imgWrap' data-position="left">
                                        <img src={ImgTeam06} className='imgFluid' alt="ADNAN AKRAM" />
                                    </div>
                                    <div className='descWrap'>
                                        <h3 className='h3 txtUppercase'>
                                            ADNAN AKRAM
                                            <strong className='desigTitle fontPoppins'>Chief Operating Officer</strong>
                                        </h3>
                                        <p>Adnan Akram leads Omelatte, Dubai's premier software firm, operating internationally. As CEO, he oversees end-to-end services, with a strong background in Agile Scrum-based product development.</p>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='teamSection'>
                <div className='container'>
                    <div className='tmInnerWrap'>
                        <article className='detailWidget txtCenter fontPoppins'>
                            <h1 className='h1'>Dev. Team</h1>
                            <p>We may not live for a hundred years, but the products of our creativity can leave a legacy long after we're gone.</p>
                        </article>
                        <ul className='tmList'>
                            <li>
                                <article className='teamWidget txtCenter'>
                                    <div className='imgWrap'>
                                        <img src={ImgTeam02} className="imgFluid" alt="MR. JAZEB"/>
                                        <span className='icn icn-arrow-large'></span>
                                    </div>
                                    <div className='descpWrap'>
                                        <h1 className='h1 txtUppercase'>
                                            Mr. Jazeb
                                            <strong className='desigTitle fontPoppins'>Team Head</strong>
                                        </h1>
                                        <span className='sigTxt'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="83" height="26" viewBox="0 0 83 26" fill="none">
                                                <path opacity="0.6" d="M10.0344 21.5803C17.1093 15.8608 25.5256 14.3604 33.2994 12.1762C47.4623 8.19675 61.7622 5.03478 76.0348 2.10711C83.2976 0.617311 84.7179 1.06221 75.8482 1.06221C59.4482 1.06221 43.0525 2.44105 26.7678 5.52679C17.9865 7.19075 10.2197 10.7141 2.07209 15.6909C-3.47468 19.0789 14.0134 16.4087 19.9874 16.4508C26.1311 16.494 71.6182 13.1883 71.6182 25" stroke="#FBB112"/>
                                            </svg>
                                        </span>
                                    </div>
                                </article>
                            </li>
                            <li>
                                <article className='teamWidget txtCenter'>
                                    <div className='imgWrap'>
                                        <img src={ImgTeam01} className="imgFluid" alt="KHAKAN ALI"/>
                                        <span className='icn icn-arrow-large'></span>
                                    </div>
                                    <div className='descpWrap'>
                                        <h1 className='h1 txtUppercase'>
                                            Khakan Ali
                                            <strong className='desigTitle fontPoppins'>Chief Techinical Officer</strong>
                                        </h1>
                                        <span className='sigTxt'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="125" height="26" viewBox="0 0 125 26" fill="none">
                                                <path opacity="0.6" d="M14.7382 21.4941C25.4966 15.7985 38.2948 14.3045 50.1161 12.1294C71.6528 8.16659 93.3981 5.01787 115.102 2.10247C126.146 0.618914 128.306 1.06194 114.818 1.06194C89.8793 1.06194 64.9471 2.43501 40.1838 5.50783C26.8305 7.16481 15.0199 10.6734 2.63028 15.6293C-5.80442 19.0032 20.7888 16.3442 29.8731 16.3861C39.2156 16.4291 108.386 13.1372 108.386 24.8994" stroke="#FBB112"/>
                                            </svg>
                                        </span>
                                    </div>
                                </article>
                            </li>
                            <li>
                                <article className='teamWidget txtCenter'>
                                    <div className='imgWrap'>
                                        <img src={ImgTeam03} className="imgFluid" alt="ARSLAN AHMAD"/>
                                        <span className='icn icn-arrow-large'></span>
                                    </div>
                                    <div className='descpWrap'>
                                        <h1 className='h1 txtUppercase'>
                                            Arslan Ahmad
                                            <strong className='desigTitle fontPoppins'>Fronted Team Lead</strong>
                                        </h1>
                                        <span className='sigTxt'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="103" height="20" viewBox="0 0 103 20" fill="none">
                                                <path opacity="0.6" d="M1 14.8198C1.95327 11.0844 16.7383 12.0481 19.1747 12.033C38.2156 11.915 56.9444 9.58 75.9113 9.24621C80.6896 9.16212 97.4979 11.2233 101.672 7.54317C104.764 4.81684 84.9953 5.01002 84.2085 4.98861C62.9509 4.41014 41.0847 4.77426 20.1229 1.04065C17.9797 0.65891 27.2769 3.07855 28.8152 3.59521C40.6411 7.56724 66.5477 4.77661 70.696 19" stroke="#FBB112"/>
                                            </svg>
                                        </span>
                                    </div>
                                </article>
                            </li>
                            <li>
                                <article className='teamWidget txtCenter'>
                                    <div className='imgWrap'>
                                        <img src={ImgTeam04} className="imgFluid" alt="ALI TASHFEEN"/>
                                        <span className='icn icn-arrow-large'></span>
                                    </div>
                                    <div className='descpWrap'>
                                        <h1 className='h1 txtUppercase'>
                                            Ali Tashfeen
                                            <strong className='desigTitle fontPoppins'>Team Head</strong>
                                        </h1>
                                        <span className='sigTxt'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="103" height="31" viewBox="0 0 103 31" fill="none">
                                                <path opacity="0.6" d="M1 13.0262C18.5265 16.6541 38.9247 14.5174 56.8058 14.4407C71.0713 14.3795 88.2723 14.7776 100.843 8.15406C106.202 5.33034 91.353 2.29634 88.6949 2.02462C74.2253 0.545536 55.7461 0.428588 41.7155 3.28194C32.6576 5.124 21.1505 6.77677 13.1482 10.8259C5.08876 14.9039 17.8173 17.2999 22.2594 18.6841C41.4879 24.6759 63.9424 27.171 84.424 27.171C90.8286 27.171 94.5902 27.2708 100.084 30" stroke="#FBB112"/>
                                            </svg>
                                        </span>
                                    </div>
                                </article>
                            </li>
                            <li>
                                <article className='teamWidget txtCenter'>
                                    <div className='imgWrap'>
                                        <img src={ImgTeam05} className="imgFluid" alt="MR. TAHSEEN"/>
                                        <span className='icn icn-arrow-large'></span>
                                    </div>
                                    <div className='descpWrap'>
                                        <h1 className='h1 txtUppercase'>
                                            MR. Tahseen
                                            <strong className='desigTitle fontPoppins'>Chief Techinical Officer</strong>
                                        </h1>
                                        <span className='sigTxt'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="99" height="28" viewBox="0 0 99 28" fill="none">
                                                <path opacity="0.6" d="M1 18.4319C31.2006 15.6804 60.4531 7.23049 90.6228 4.72291C95.4769 4.31946 103.14 1.83418 93.0342 1.39087C82.0988 0.91117 71.1008 1.29567 60.1591 1.29567C51.4539 1.29567 46.8098 -1.52286 47.2984 10.2446C47.8939 24.5848 65.0228 27 74.7881 27" stroke="#FBB112"/>
                                            </svg>
                                        </span>
                                    </div>
                                </article>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;