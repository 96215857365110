import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "MVP Development | Omelatte";  
	}, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>MVP </span>
                            Development
                        </h1>
                        <p>Speeding your product to market.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Accelerate Your Product to Market</h2>
                            <div className='decpWrap'>
                                <p>Our MVP development service is designed for speed and efficiency. We help you quickly transform your concept into a working prototype, gather user feedback, iterate, and launch ahead of your competitors.</p>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Advantages of MVP Development</h2>
                            <ul className='ftrList'>
                                <li><span>Speed to Market:</span> Get your product in front of users faster.</li>
                                <li><span>Real-World Feedback:</span> Gather insights and make informed decisions.</li>
                                <li><span>Cost-Effective:</span> Focus resources on essential features.</li>
                                <li><span>Iteration:</span> Continuously improve based on user feedback</li>
                                <li><span>Competitive Edge:</span> Stay ahead in a rapidly changing market</li>
                                <li>MVP development offers a range of advantages for startups and businesses aiming for rapid product launch and iteration.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;